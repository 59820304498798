import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import "./services.scss"

const Services = () => {
  const data = useStaticQuery(graphql`
    query NonPageQuery {
      allSanityServices(sort: { fields: [createdAt], order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      {data.allSanityServices.edges.map(({ node: service }) => (
        <li key={service.slug.current}>
          <div className="service">
            <Image
              className="image"
              fluid={service.image.asset.fluid}
              alt={service.title}
            />
            <h3 className="service-title">{service.title}</h3>
          </div>
        </li>
      ))}
    </>
  )
}
export default Services