import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.scss"
import Services from "../components/services"

const IndexPage = () => (
  <Layout>
    <SEO title="Window Treatments &amp; Upholstery" />
    <div className="title">
      <h1>Window Treatments &amp; Upholstery By Berkeley Decor</h1>
      <h1 className="lets">Let's Create Your Perfect Home</h1>
    </div>
    <ul>
      <Services />
    </ul>    
  </Layout>
)
export default IndexPage
